import Filter from 'bad-words';
import React from 'react';
import { Nullable } from '../types/UtilTypes';

export function useNoteFilter() {
  const filter = new Filter();

  const filterNote = React.useCallback((note: Nullable<string>) => {
    try {
      return !!note ? filter.clean(note) : null;
    } catch {
      return note;
    }
  }, []);

  return {
    filterNote,
  };
}
