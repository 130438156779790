import React from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from '../Modal';
import { useMemberRedirect } from '../../../hooks/utils';

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  visible: boolean;
  onClose: () => void;
  handleOpen: () => void;
};

const NoRsoModal: React.FC<Props> = ({
  className,
  visible,
  onClose: handleClose,
  handleOpen,
}) => {
  const redirectToMemberPage = useMemberRedirect({ destination: 'rso' });

  return (
    <Modal
      className={className}
      onClose={handleClose}
      visible={visible}
      contentClassName={'w-auto'}
    >
      <div className={'px-5 py-6 flex flex-col items-center'}>
        <div className={'text-[15px] font-bold'}>
          <FormattedMessage id="Your account is not linked to your Riot account." />
        </div>
        <div
          className={
            'text-[12px] mt-2 mb-[26px] text-center max-w-[240px] break-keep'
          }
        >
          <FormattedMessage id="Linking with your Riot account will shorten the waiting period between posts." />
        </div>
        <div className={'flex gap-2'}>
          <button
            className={
              'bg-gray-700 border-gray-700 hover:bg-gray-light text-gray-100 cursor-pointer rounded py-2.5 px-4 whitespace-nowrap min-w-[130px]'
            }
            onClick={() => {
              handleClose();
              handleOpen();
            }}
          >
            <FormattedMessage id="Not now" />
          </button>
          <button
            className={
              'bg-green border-green-500 text-white cursor-pointer rounded text-bold py-2.5 px-4 whitespace-nowrap min-w-[130px]'
            }
            onClick={redirectToMemberPage}
          >
            <FormattedMessage id="Link with Riot account" />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default NoRsoModal;
