import { ArrowDownCircleIcon } from '@heroicons/react/24/solid';
import { IconButton } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { dataLayer, GTMEventInfoType, GTMEventType } from '../lib/analytics';

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  disabled?: boolean;
  onFetchMore: () => Promise<void>;
};

const ViewMoreButton: React.FC<Props> = ({
  className,
  disabled = false,
  onFetchMore,
}) => {
  return (
    <div className={classNames('text-center', className)}>
      <IconButton
        color="primary"
        disabled={disabled}
        onClick={async () => {
          dataLayer(
            {
              op_event: GTMEventInfoType.CLICK_LIST_MORE,
            },
            GTMEventType.CLICK
          );
          await onFetchMore();
        }}
      >
        <ArrowDownCircleIcon width={24} height={24} />
      </IconButton>
    </div>
  );
};

export default ViewMoreButton;
