import classNames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';
import { AdSlot } from '../types/AdManagerTypes';
import { AdSenseFormat, AD_SENSE_MAP, SizeClass } from '../types/AdSenseTypes';
import { isBrowser, isMobile, isProduction } from '../utils/misc';
import { useMember } from '../queries/member';

type Props = {
  className?: string;
  slot: AdSlot;
};

const AdSense: React.FC<Props> = ({ className, slot: slotName }) => {
  const { query, pathname } = useRouter();
  const { user } = useMember();

  const isDebugging = query && query['_pt'] !== undefined;
  const [slot, sizeClassName] = AD_SENSE_MAP[slotName];

  const hidden =
    isMobile() && (slotName === AdSlot.LEFT || slotName === AdSlot.RIGHT);

  React.useEffect(() => {
    if (!isBrowser() || !isProduction() || isDebugging || hidden) return;

    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error(e);
    }
  }, [slot, pathname]);

  if (hidden || isDebugging || user?.adFree) {
    return null;
  }

  return (
    <ins
      key={`ad-sense-${slotName}-${pathname}`}
      className={classNames(
        {
          'border border-dashed border-gray-500': !isProduction(),
        },
        'adsbygoogle inline-block',
        sizeClassName,
        className
      )}
      data-ad-client="ca-pub-8377914384184168"
      data-ad-slot={slot}
      data-ad-format={getAdFormat(sizeClassName)}
      data-full-width-responsive={false}
    />
  );

  function getAdFormat(sizeClass: SizeClass): AdSenseFormat {
    if (sizeClass === SizeClass.RECTANGLE && !isMobile()) return 'rectangle';
    if (sizeClass === SizeClass.LEADERBOARD) return 'horizontal';
    return 'auto';
  }
};

export default AdSense;
