import { Popover } from '@headlessui/react';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  onEdit?: () => void;
  onDelete?: () => void;
  onReport?: () => void;
};

const EtcPopover: React.FC<Props> = ({
  className,
  onEdit,
  onDelete,
  onReport,
}) => {
  return (
    <Popover
      className={classNames(
        'relative flex justify-center items-center',
        className
      )}
      onClick={stopPropergation}
    >
      <Popover.Button as="div">
        <svg
          className="w-6 h-6 hover:text-gray-200 text-gray-600"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.167 8.333a1.667 1.667 0 1 1 0 3.334 1.667 1.667 0 0 1 0-3.334zm5.833 0a1.667 1.667 0 1 1 0 3.334 1.667 1.667 0 0 1 0-3.334zm5.833 0a1.667 1.667 0 1 1 0 3.334 1.667 1.667 0 0 1 0-3.334z"
            fill="currentColor"
          />
        </svg>
      </Popover.Button>

      <Popover.Panel className="absolute top-7 right-3 bg-gray-800 w-28 z-10 shadow-lg rounded">
        {onEdit && (
          <PopoverList
            onClick={onEdit}
            imagePath="//opgg-desktop-data.akamaized.net/download/duo/images/icons/edit.svg"
            name="Edit"
          />
        )}

        {onDelete && (
          <PopoverList
            onClick={onDelete}
            imagePath="//opgg-desktop-data.akamaized.net/download/duo/images/icons/delete.svg"
            name="Delete"
          />
        )}

        {onReport && (
          <PopoverList
            onClick={onReport}
            imagePath="//opgg-desktop-data.akamaized.net/download/duo/images/icons/troll.svg"
            nameClassName="text-red-500"
            name="Report"
          />
        )}
      </Popover.Panel>
    </Popover>
  );

  function stopPropergation(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
  }
};

type PopoverListProps = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  nameClassName?: React.HTMLAttributes<HTMLDivElement>['className'];
  name: string;
  imagePath: string;
  onClick: () => void;
};

export const PopoverList: React.FC<PopoverListProps> = ({
  className,
  imagePath,
  name,
  nameClassName,
  onClick: handleClick,
}) => {
  return (
    <Popover.Button
      as="div"
      className={classNames(
        className,
        'flex items-center h-9 border-b border-gray-900 hover:bg-gray-700 px-2'
      )}
      onClickCapture={handleClick}
    >
      <img src={imagePath} className="w-5 h-5" />
      <p className={classNames(nameClassName, 'text-xs px-4')}>
        <FormattedMessage id={name} />
      </p>
    </Popover.Button>
  );
};

export default EtcPopover;
