export enum AdSlot {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',

  HOME = 'HOME',
  BOTTOM = 'BOTTOM',
  VIEW_MORE = 'VIEW_MORE',

  HOME_MOBILE = 'HOME_MOBILE',
  BOTTOM_MOBILE = 'BOTTOM_MOBILE',
  VIEW_MORE_MOBILE = 'VIEW_MORE_MOBILE',

  TROLL_HOME = 'TROLL_HOME',
  TROLL_MIDDLE = 'TROLL_MIDDLE',
  TROLL_BOTTOM = 'TROLL_BOTTOM',
  MOBILE_TROLL_HOME = 'MOBILE_TROLL_HOME',
  MOBILE_TROLL_MIDDLE = 'MOBILE_TROLL_MIDDLE',
  MOBILE_TROLL_BOTTOM = 'MOBILE_TROLL_BOTTOM',

  CLAN_HOME = 'CLAN_HOME',
  CLAN_BOTTOM = 'CLAN_BOTTOM',
  MOBILE_CLAN_HOME = 'MOBILE_CLAN_HOME',
  MOBILE_CLAN_BOTTOM = 'MOBILE_CLAN_BOTTOM',
}

export interface IAdManager {
  id: string;
  slot: string;
  sizes: number[][];
}

export const AD_MANAGER_MAP: { [key in keyof typeof AdSlot]: IAdManager } = {
  HOME: {
    slot: '/114726905/PC_Duo_Main',
    sizes: [[970, 250]],
    id: '1634543497033',
  },
  VIEW_MORE: {
    slot: '/114726905/Duo_PC_ViewMore',
    sizes: [[970, 90]],
    id: '1634543437353',
  },
  BOTTOM: {
    slot: '/114726905/Duo_PC_Bottom',
    sizes: [[970, 250]],
    id: '1634543287556',
  },
  LEFT: {
    slot: '/114726905/Duo_PC_LeftTop',
    sizes: [[300, 600]],
    id: '1634543514570',
  },
  RIGHT: {
    slot: '/114726905/Duo_PC_RightTop',
    sizes: [[300, 600]],
    id: '1634543460870',
  },
  TROLL_HOME: {
    slot: '/114726905/Duo_PC_Troll_Main',
    sizes: [[970, 250]],
    id: '1640005061135',
  },
  TROLL_MIDDLE: {
    slot: '/114726905/Duo_PC_Troll_Mid',
    sizes: [[300, 250]],
    id: '1640005083912',
  },
  TROLL_BOTTOM: {
    slot: '/114726905/Duo_Troll_Bottom',
    sizes: [[970, 250]],
    id: '1640005163022',
  },
  CLAN_HOME: {
    slot: '/114726905/Duo_Clan_Main',
    sizes: [[970, 250]],
    id: '1641789832976',
  },
  CLAN_BOTTOM: {
    slot: '/114726905/Duo_Clan_Bottom',
    sizes: [[970, 90]],
    id: '1641789783872',
  },
  // Mobile
  HOME_MOBILE: {
    slot: '/114726905/Duo_MW_Main',
    sizes: [[300, 50]],
    id: '1634544448606',
  },
  VIEW_MORE_MOBILE: {
    slot: '/114726905/Duo_MW_ViewMore',
    sizes: [[300, 50]],
    id: '1634544430586',
  },
  BOTTOM_MOBILE: {
    slot: '/114726905/Duo_MW_Bottom',
    sizes: [[300, 250]],
    id: '1634544463497',
  },
  MOBILE_TROLL_HOME: {
    slot: '/114726905/Duo_Troll_MW_Main',
    sizes: [[300, 50]],
    id: '1640005220784',
  },
  MOBILE_TROLL_MIDDLE: {
    slot: '/114726905/Duo_Troll_MW_Mid',
    sizes: [[300, 50]],
    id: '1640005253702',
  },
  MOBILE_TROLL_BOTTOM: {
    slot: '/114726905/Duo_Troll_MW_Bottom',
    sizes: [[300, 250]],
    id: '1640005119458',
  },
  MOBILE_CLAN_HOME: {
    slot: '/114726905/Duo_MW_Clan_Main',
    sizes: [[300, 250]],
    id: '1641789989608',
  },
  MOBILE_CLAN_BOTTOM: {
    slot: '/114726905/Duo_MW_Clan_Bottom',
    sizes: [[300, 250]],
    id: '1641790005843',
  },
};
