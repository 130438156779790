import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { NO_RSO_EXAMPPLE_MEMO_IDS } from '../utils/constants';

interface SelectProps {
  selected: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

const RsoNoteSelect: React.FC<SelectProps> = ({ selected, setValue }) => {
  const intl = useIntl();
  const exampleMemos = NO_RSO_EXAMPPLE_MEMO_IDS.map((id) =>
    intl.formatMessage({ id })
  );
  return (
    <div className={'select-container'}>
      <select
        name="rso_note"
        value={selected}
        onChange={(e) => setValue(e.target.value)}
        className={'cursor-default w-full'}
      >
        {exampleMemos.map((memo) => (
          <option key={memo} value={memo}>
            {memo}
          </option>
        ))}
      </select>
    </div>
  );
};

export default RsoNoteSelect;
