import { AdSlot } from './AdManagerTypes';

export enum SizeClass {
  BANNER = 'w-[320px] h-[100px] lg:w-[970px] lg:h-[250px]',
  LEADERBOARD = 'w-[320px] h-[50px] lg:w-[970px] lg:h-[90px]',
  RECTANGLE = 'w-[320px] h-[50px] lg:w-[300px] lg:h-[250px]',
  SKYSCRAPER = 'w-0 h-0 lg:w-[300px] lg:h-[600px] lg:block',
}

export type AdSenseFormat = 'auto' | 'vertical' | 'horizontal' | 'rectangle';

export const AD_SENSE_MAP: {
  [key in keyof typeof AdSlot]: [number, SizeClass];
} = {
  LEFT: [9806568291, SizeClass.SKYSCRAPER],
  RIGHT: [7946691712, SizeClass.SKYSCRAPER],

  HOME: [5156876841, SizeClass.BANNER],
  BOTTOM: [7016753421, SizeClass.BANNER],
  VIEW_MORE: [8521406782, SizeClass.LEADERBOARD],
  HOME_MOBILE: [4359047911, SizeClass.BANNER],
  BOTTOM_MOBILE: [7152484790, SizeClass.BANNER],
  VIEW_MORE_MOBILE: [6605689884, SizeClass.LEADERBOARD],

  TROLL_HOME: [7875809417, SizeClass.BANNER],
  TROLL_MIDDLE: [5067303934, SizeClass.RECTANGLE],
  TROLL_BOTTOM: [5058074381, SizeClass.BANNER],
  MOBILE_TROLL_HOME: [2431911043, SizeClass.BANNER],
  MOBILE_TROLL_MIDDLE: [7492666038, SizeClass.RECTANGLE],
  MOBILE_TROLL_BOTTOM: [6179584369, SizeClass.BANNER],

  CLAN_HOME: [5719217657, SizeClass.BANNER],
  CLAN_BOTTOM: [7807159096, SizeClass.BANNER],
  MOBILE_CLAN_HOME: [2754142966, SizeClass.BANNER],
  MOBILE_CLAN_BOTTOM: [6110934048, SizeClass.BANNER],
};
