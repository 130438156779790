import { gql, useMutation } from '@apollo/client';
import { LoLPartner, Position } from '../types/lol/partner.type';
import { Nullable } from '../types/UtilTypes';

export const ADD_PARTNER = gql`
  mutation AddPartner(
    $note: String
    $passcode: String
    $position: String!
    $position2: String
    $champion: String
    $champion2: String
    $requiredPosition: [String!]!
    $region: String!
    $summonerName: String!
    $gameName: String!
    $tagLine: String!
    $queueType: QueueType!
    $options: Int!
  ) {
    addPartner(
      note: $note
      passcode: $passcode
      position: $position
      position2: $position2
      champion: $champion
      champion2: $champion2
      requiredPosition: $requiredPosition
      region: $region
      summonerName: $summonerName
      gameName: $gameName
      tagLine: $tagLine
      queueType: $queueType
      options: $options
    ) {
      id
    }
  }
`;

export const DELETE_PARTNER = gql`
  mutation DeletePartner($id: ID!, $passcode: String) {
    deletePartner(id: $id, passcode: $passcode) {
      id
    }
  }
`;

export const UPDATE_PARTNER = gql`
  mutation UpdatePartner(
    $id: ID!
    $note: String
    $passcode: String
    $position: String!
    $position2: String
    $champion: String
    $champion2: String
    $requiredPosition: [String!]!
    $queueType: QueueType!
    $options: Int!
  ) {
    updatePartner(
      id: $id
      note: $note
      passcodeConfirm: $passcode
      position: $position
      requiredPosition: $requiredPosition
      queueType: $queueType
      options: $options
      position2: $position2
      champion: $champion
      champion2: $champion2
    ) {
      id
    }
  }
`;

export const REPORT_PARTNER = gql`
  mutation ReportPartner(
    $partnerId: ID!
    $note: String!
    $gameKind: GameKind!
  ) {
    addReport(note: $note, partnerId: $partnerId, gameKind: $gameKind) {
      id
    }
  }
`;

export interface LoLPartnerParams {
  note: Nullable<string>;
  passcode: string;
  position: Nullable<Position>;
  position2?: Nullable<Position>;
  champion?: string;
  champion2?: string;
  requiredPosition: Position[];
  queueType: string;
  options: number;
}

export interface IRegisterPartnerParams extends LoLPartnerParams {
  region: string;
  summonerName: string;
  gameName?: string;
  tagLine?: string;
  recaptcha: Nullable<string>;
}

export interface IUpdatePartnerParams extends LoLPartnerParams {
  id: string;
}

export function useAddPartner() {
  const [addPartner, { data, ...rest }] = useMutation<
    { addPartner: LoLPartner },
    IRegisterPartnerParams
  >(ADD_PARTNER);

  return {
    addPartner,
    partner: data?.addPartner,
    ...rest,
  };
}

export function useDeletePartner() {
  const [deletePartner, { data, ...rest }] = useMutation<{
    deletePartner: LoLPartner;
  }>(DELETE_PARTNER);

  return {
    deletePartner,
    partner: data?.deletePartner,
    ...rest,
  };
}

export function useEditPartner() {
  const [editPartner, { data, ...rest }] = useMutation<
    { updatePartner: LoLPartner },
    IUpdatePartnerParams
  >(UPDATE_PARTNER);

  return {
    editPartner,
    partner: data?.updatePartner,
    ...rest,
  };
}

export function useReportPartner() {
  const [reportPartner, { data, ...rest }] = useMutation<{
    addReport: { id: number };
  }>(REPORT_PARTNER);

  return {
    reportPartner,
    result: data?.addReport,
    ...rest,
  };
}
