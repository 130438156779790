import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import React from 'react';
import { noop } from '../utils/misc';

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  enabled: boolean;
  onChange: (enabled: boolean) => void;
};

const OptionSwitch: React.FC<Props> = ({
  className,
  enabled: defaultEnabled,
  onChange = noop,
}) => {
  const [enabled, setEnabled] = React.useState(defaultEnabled);

  return (
    <Switch
      checked={enabled}
      onChange={handleChange}
      className={classNames(
        'relative inline-flex flex-shrink-0 h-8 w-16 border-2 border-clear rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none p-0',
        {
          'bg-green-500': enabled,
          'bg-gray-700 sm:hover:bg-gray-700': !enabled,
        },
        className
      )}
    >
      <span
        aria-hidden="true"
        className={`${enabled ? 'translate-x-8' : 'translate-x-0'}
          pointer-events-none inline-block h-7 w-7 rounded-full bg-white transform transition ease-in-out duration-200`}
      />
    </Switch>
  );

  function handleChange(value: boolean) {
    setEnabled(value);
    onChange(value);
  }
};

export default OptionSwitch;
