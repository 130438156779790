import classNames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCertifiedSummoners } from '../queries/summoners';
import { dataLayer, GTMEventInfoType, GTMEventType } from '../lib/analytics';
import { useMemberRedirect } from '../hooks/utils';
interface Props {
  selected: boolean;
  onChange: (selected: boolean) => void;
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
}

const CertifiedToggle = ({ selected, onChange, className }: Props) => {
  const [showRSOOnlyTooltip, setShowRSOOnlyTooltip] = React.useState<any>();
  const { summoners, loading } = useCertifiedSummoners();
  const redirectToMemberPage = useMemberRedirect({ destination: 'rso' });

  return (
    <div className="relative">
      <div
        className={classNames(
          `flex items-center p-2`,
          `relative border border-solid rounded bg-gray-800 hover:bg-gray-600 border-gray-700`,
          'cursor-pointer',
          {
            'text-green border-green': selected,
          },
          className
        )}
        onClick={() => handleClick()}
      >
        <span className="xs:hidden text-sm">
          <FormattedMessage id="Show Only" />
        </span>
        <div
          className={classNames('w-[24px] h-[24px]', {
            'bg-green': selected,
            'bg-gray-400': !selected,
          })}
          style={{
            mask: 'url(//opgg-desktop-data.akamaized.net/download/duo/images/icons/riot.svg) center / containno-repeat',
            WebkitMask:
              'url(//opgg-desktop-data.akamaized.net/download/duo/images/icons/riot.svg) center / contain no-repeat',
          }}
        ></div>
      </div>
      {showRSOOnlyTooltip && (
        <div className="absolute left-0 right-0 bottom-0">
          <div className="relative flex justify-center">
            <div
              className="bg-gray-600 absolute"
              style={{
                width: '13px',
                height: '8px',
                clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
                bottom: '-15px',
              }}
            ></div>
            <div
              className="absolute"
              style={{
                zIndex: 1,
                minWidth: '100%',
                top: 'calc(100% + 14px)',
              }}
            >
              <div className="relative whitespace-nowrap">
                <div
                  className="flex"
                  style={{
                    justifyContent: 'center',
                    position: 'relative',
                  }}
                >
                  <div className="bg-gray-600 py-[8px] px-[12px] rounded">
                    <p className="text-sm text-center">
                      <FormattedMessage
                        id={
                          // RSO 로그인 유저만 사용할 수 있는 기능입니다.
                          'Only RSO users Can use this Filter'
                        }
                        defaultMessage={'Only RSO users{br}Can use this filter'}
                        values={{ br: <br /> }}
                      />
                    </p>
                    <button
                      onClick={() => {
                        dataLayer(
                          {
                            op_event:
                              GTMEventInfoType.CLICK_RSO_SHOW_ONLY_FILTER,
                          },
                          GTMEventType.CLICK
                        );
                        redirectToMemberPage();
                      }}
                      className="hover:bg-transparent hover:border-white hover:text-white text-[12px] mt-2 bg-transparent p-2 border-solid border-[1px] border-[#00BBA3] rounded-[4px] flex justify-center items-center text-[#00BBA3]"
                    >
                      <FormattedMessage id={'Connect with Riot Game account'} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  function handleClick() {
    if (!summoners || summoners.length === 0) {
      if (showRSOOnlyTooltip) clearTimeout(showRSOOnlyTooltip);
      setShowRSOOnlyTooltip(
        setTimeout(() => setShowRSOOnlyTooltip(null), 3000)
      );
      return;
    }
    onChange(!selected);
    dataLayer(
      {
        op_event: GTMEventInfoType.CLICK_CERTIFIED_FILTER,
        op_event_value: !selected,
      },
      GTMEventType.CLICK
    );
  }
};

export default CertifiedToggle;
