import classNames from 'classnames';
import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

type Props = {
  className?: string;
  label?: string;
};

const PasscodeInput = React.forwardRef<
  HTMLInputElement,
  Props & ReturnType<UseFormRegister<{ passcode: string }>>
>(function PasscodeInput({ className, onChange, name }, ref) {
  const intl = useIntl();

  return (
    <div className={classNames(className)}>
      <div
        className={classNames('flex gap-1 mb-2 mt-3 items-center', className)}
      >
        <label className="block text-2xs text-gray-400">
          <FormattedMessage id="Passcode for delete" />
        </label>
        <div className="text-red-500">*</div>
      </div>
      <input
        type="text"
        security="true"
        autoComplete="off"
        inputMode="numeric"
        className="w-full p-3 input-password border rounded border-solid border-gray-600 text-gray-100 text-sm focus:outline-none bg-gray-850"
        placeholder={intl.formatMessage({
          id: 'Enter 4 digits, please.',
        })}
        ref={ref}
        name={name}
        onChange={(e) => {
          e.target.value = e.target.value.replace(/[^0-9]/g, '');
          onChange(e);
        }}
        minLength={4}
        maxLength={4}
      />
    </div>
  );
});

export default PasscodeInput;
