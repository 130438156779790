import classNames from 'classnames';
import { ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ClashTier } from '../types/lol/clash.type';
import { Tier } from '../types/lol/summoner.type';
import { dataLayer, GTMEventInfoType, GTMEventType } from '../lib/analytics';
interface Props<T> {
  tiers: T[];
  selected?: T;
  hideSign?: boolean;
  onChange: (tier: T) => void;
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  selectClassName?: React.HTMLAttributes<HTMLSelectElement>['className'];
}

const TierSelect = <T extends string | number>({
  tiers,
  selected,
  hideSign,
  onChange,
  className,
  selectClassName,
}: Props<T>) => {
  const intl = useIntl();

  return (
    <div className={classNames('select-container', className)}>
      <select
        name="tier"
        value={
          typeof selected === 'string' ? selected?.toUpperCase() : selected
        }
        onChange={handleChangeTier}
        className={selectClassName}
      >
        {tiers.map((tier: T) => (
          <option key={tier} value={tier} className="capitalize">
            {typeof tier === 'string' &&
              intl.formatMessage({
                id: tier === Tier.ALL ? 'All Tiers' : tier,
              })}
            {typeof tier === 'number' && tier > 0 && (
              <>
                <FormattedMessage id="Tier" /> {ClashTier[tier]}{' '}
                {!hideSign && tier > 1 && '+'}
              </>
            )}
            {typeof tier === 'number' && tier === 0 && (
              <FormattedMessage id="All Tiers" />
            )}
          </option>
        ))}
      </select>
    </div>
  );

  function handleChangeTier(event: ChangeEvent<HTMLSelectElement>): void {
    onChange(event.target.value as T);
    dataLayer(
      {
        op_event: GTMEventInfoType.CLICK_TIER_FILTER,
        op_event_value: event.target.value,
      },
      GTMEventType.CLICK
    );
  }
};

export default TierSelect;
