import React from 'react';
import { isBrowser } from '../utils/misc';

export default function useDeviceDetect(): { isMobile: boolean } {
  const [isMobile, setMobile] = React.useState(false);

  React.useEffect(() => {
    if (!isBrowser()) return;

    const userAgent =
      typeof window.navigator === 'undefined' ? '' : navigator.userAgent;

    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );

    setMobile(mobile);
  }, []);

  return { isMobile };
}
