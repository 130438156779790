import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { NO_RSO_EXAMPPLE_MEMO_IDS } from '../utils/constants';

const useRsoNote = ({
  isCertified,
  partnerNote,
}: {
  isCertified: boolean;
  partnerNote?: string;
}) => {
  const intl = useIntl();
  const exampleMemos = NO_RSO_EXAMPPLE_MEMO_IDS.map((id) =>
    intl.formatMessage({ id })
  );
  const [note, setNote] = useState<string>(partnerNote || exampleMemos[0]);

  useEffect(() => {
    if (isCertified) {
      setNote(partnerNote || '');
    } else {
      if (!partnerNote || !exampleMemos.includes(partnerNote)) {
        setNote(exampleMemos[0]);
      } else {
        setNote(partnerNote);
      }
    }
  }, [isCertified]);

  return [note, setNote] as [string, Dispatch<SetStateAction<string>>];
};

export default useRsoNote;
