import React, { useEffect } from 'react';
import striptags from 'striptags';
import { useIntl } from 'react-intl';

interface InputProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

const RsoNoteInput: React.FC<InputProps> = ({ value, setValue }) => {
  const intl = useIntl();

  useEffect(() => {
    !value && setValue('');
  }, []);
  return (
    <input
      type="text"
      autoComplete="nope"
      className="w-full p-3 border rounded border-solid border-gray-600 text-gray-100 text-sm focus:outline-none bg-gray-850"
      value={value}
      onChange={(e) => setValue(striptags(e.target.value))}
      placeholder={intl.formatMessage({
        id: 'Looking for a DUO partner.',
      })}
    />
  );
};

export default RsoNoteInput;
